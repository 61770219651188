<template>
  <div class="flex relative items-center justify-center portfolio-template bg-cover bg-center" :style="{ backgroundImage: 'url(' + require('@/assets/background-work.png') + ')' }">
    <div class="portfolio-inner">
      <div class="portfolio-left-width float-left w-2/5 inline-block">
        <div class="portfolio-left m-5" :class="pageName">
          <img class="p-4 rounded-3xl" src="@/assets/singletrack-v2/v2-redesign-dashboard.png">
          <img class="p-4 rounded-3xl" src="@/assets/singletrack-v2/v2-redesign-library.png">
          <img class="p-4 rounded-3xl" src="@/assets/singletrack-v2/v2-redesign-research-preview.png">
        </div>
      </div>
      <div class="portfolio-right-width float-right w-3/5 inline-block">
        <div class="portfolio-right m-5">
          <div class="portfolio-title">
            <div class="mb-8 portfolio-title-header text-left" :class="pageName">
              Singletrack Research Portal 2022
            </div>
            <div class="mb-8 portfolio-title-images text-left" :class="pageName">
              <Carousel :breakpoints="breakpoints">
                <Slide v-for="image in carouselImages" :key="image">
                  <div class="carousel__item"><img :src="image"></div>
                </Slide>
                <template #addons>
                  <Navigation />
                </template>
              </Carousel>
            </div>
          </div>
          <div class="mb-8 portfolio-requirements">
            <div class="portfolio-requirements-header text-left" :class="pageName">
              Product Requirements
            </div>
            <div class="portfolio-requirements-content text-left" :class="pageName" style="background-position: 50% 65%; background-repeat: no-repeat; background-attachment: fixed" :style="{ backgroundImage: 'url(' + require('@/assets/background-work.png') + ')' }">
              <p class="pb-4 relative">
                Various client rollouts have added additional custom features back into our standard portal. Due to this, we were in need of a serious look into how our current components fit together as a system.
              </p>
              <p class="pb-4 relative">
                We gathered all the pain points that our clients, potential clients, users, and internal stakeholders have heard.
              </p>
              <p class="relative">
                Our main objects were as follows: 
                <br>
                &bull; Convert from an older table based layout into a newer modern look and feel. 
                <br>
                &bull; Provide a consistently sized portal experience. 
                <br>
                &bull; Components act as a cohesive unit no matter where they would live in the portal.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      pageName: 'singletrack2022',
      carouselImages: [
        require("@/assets/singletrack-v2/v2-redesign-analyst-preview.png"),
        require("@/assets/singletrack-v2/v2-redesign-company-profile.png"),
        require("@/assets/singletrack-v2/v2-redesign-sector-profile.png"),
        require("@/assets/singletrack-v2/v2-redesign-roadshows.png"),
        require("@/assets/singletrack-v2/v2-redesign-roadshow-preview.png"),
      ],
      breakpoints: {
        // 700px and up
        350: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        992: {
          itemsToShow: 2.5,
          snapAlign: 'start',
        },
      },
    }
  }
}
</script>

<style>
.text-shadow {
  text-shadow: 0 3px 6px #0003;
}
.portfolio-template {
  height: 1080px;
  border-radius: 8px;
}
.portfolio-inner {
  width: 1060px;
}
.portfolio-left {
  background: rgb(0,220,255);
  background: linear-gradient(150deg, rgba(0,220,255,0.6) 0%, rgba(18,11,219,0.6) 100%);
  border-radius: 8px;
}
.portfolio-title-header {
  font-size: 24px;
  padding: 10px 15px;
  background: rgb(0,220,255);
  background: linear-gradient(150deg, rgba(0,220,255,0.6) 0%, rgba(18,11,219,0.6) 100%);
  border-radius: 8px;
  color: white;
}

.portfolio-requirements-header {
  font-size: 19px;
  padding: 10px 15px;
  color: white;
  border-radius: 8px 8px 0 0;
  background: rgb(0,220,255);
  background: linear-gradient(150deg, rgba(0,220,255,0.6) 0%, rgba(18,11,219,0.6) 100%);
}

.portfolio-requirements-content {
  font-size: 15px;
  padding: 15px;
  color: white;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
}

.portfolio-requirements-content p {
  z-index: 1;
}

.portfolio-requirements-content:before {
  position: absolute;
  content: '';
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  filter: blur(10px);
  border-radius: 15px;
}

.portfolio-requirements-content:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #001113b5;
}

.portfolio-title-images {
  height: 125px;
  text-align: justify;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;
}

.portfolio-title-images img {
  vertical-align: top;
  display: inline-block;
}

.stretch {
  width: 100%;
  display: inline-block;
  font-size: 0;
  line-height: 0
}
.portfolio-title-images.singletrack2022 .carousel__prev, .portfolio-title-images.singletrack2022 .carousel__next {
    background-color: #036194;
}
</style>
