<template>
  <div class="portfolio-nav shadow-md">
    <NavBar></NavBar>
  </div>
  <div id="nav" class="mt-5 mb-5">
      <router-link to="/portfolio/Singletrack">Singletrack Research</router-link> |
      <router-link to="/portfolio/Singletrack2022">Singletrack Research 2022</router-link>
  </div>
  <div class="portfolio-wrapper m-5 relative shadow-lg">
    <Singletrack2022></Singletrack2022>
  </div>
  <SingletrackStudy2022></SingletrackStudy2022>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Singletrack2022 from '@/components/Singletrack2022.vue'
import SingletrackStudy2022 from '@/components/SingletrackStudy2022.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Singletrack2022,
    SingletrackStudy2022
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
.portfolio-nav {
  padding-bottom: 25px;
}

@media screen and (min-width: 992px) {
  .portfolio-wrapper.relative {
    margin-bottom: 150px;
  }
}
</style>
