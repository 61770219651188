<template>
  <div class="flex h-screen relative items-center justify-center services-template section-background relative">
    <div class="services-inner max-w-xlg">
      <img class="services-stripes-left" :src="require('@/assets/services-stripes-left.png')">
      <h2 class="text-5xl mb-12">{{headerEmphasis}} <span>{{headerItalic}}</span></h2>
      <div class="card-wrapper">
        <div class="content-card border m-5 rounded-md">
          <img class="inline-block w-1/2 h-1/2 card-image align-top" :src="cards[0].image">
          <div class="inline-block w-1/2 p-6 align-top card-text">
            <h3 class="mb-2 font-bold text-lg text-left">{{cards[0].cardHeader}}</h3>
            <p class="whitespace-pre-line text-left text-sm py-2">{{cards[0].cardContent}}</p>
          </div>
        </div>
        <div class="content-card border m-5 rounded-md">
          <img class="inline-block w-1/2 h-1/2 card-image align-top" :src="cards[1].image">
          <div class="inline-block w-1/2 p-6 align-top card-text">
            <h3 class="mb-2 font-bold text-lg text-left">{{cards[1].cardHeader}}</h3>
            <p class="whitespace-pre-line text-left text-sm py-2">{{cards[1].cardContent}}</p>
          </div>
        </div>
        <div class="content-card border m-5 rounded-md">
          <img class="inline-block w-1/2 h-1/2 card-image align-top" :src="cards[2].image">
          <div class="inline-block w-1/2 p-6 align-top card-text">
            <h3 class="mb-2 font-bold text-lg text-left">{{cards[2].cardHeader}}</h3>
            <p class="whitespace-pre-line text-left text-sm py-2">{{cards[2].cardContent}}</p>
          </div>
        </div>
      </div>
      <img class="services-stripes-right" :src="require('@/assets/services-stripes-right.png')">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerEmphasis: "Comprehensive",
      headerItalic:"Services",
      cards: [
        {image: require("@/assets/services-1.png"), cardHeader: "UX Workshops", cardContent: "By engaging in a root-cause analysis, we can slowly forge a product into the shape of success. \n\n If a user mentions that they do not like the product, it's our job to dig deeper and find out why. \n\n These workshops are crucial to clearing up any assumptions."},
        {image: require("@/assets/services-2.png"), cardHeader: "Lo-Fi/Hi-Fi Mockups", cardContent: "Converting ideas into tangible user journeys always provides a basis for further in-depth discussion. \n\n Be it clients, developers, analysts, even QA; everybody benefits from a visual representation of an idea. \n \n With a foot in both design and development, we keep in mind both the user and the developer."},
        {image: require("@/assets/services-3.png"), cardHeader: "Workable Prototypes", cardContent: "Turning a mock-up into a baseline product allows for additional user testing and feedback gathering before development begins. \n \n Whether we aid in the development process, or hand off our prototypes as-is, it's beneficial understanding the limitations of technology. \n\n We create experiences that can feasibly be built."}
      ]
    }
  }
}
</script>

<style>
.services-template {
  width: 100%;
  overflow: hidden;
}
.services-template:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5vw 100vw 0 0;
  border-color: #f1f1f1 transparent transparent transparent;
  filter: drop-shadow(rgba(0, 0, 0, 0.23) 1px 1px 18px);
  top: 0;
  position: absolute;
  left: 0;
  content: "";
}
.services-template:after {
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  border-width: 5vw 100vw 0vw 0vw;
  height: 0;
  width: 0;
  bottom: 0;
  position: absolute;
  left: 0;
  content: "";
  filter: drop-shadow(1px 1px 18px #0000003b);
}
.services-template .card-image {
  height: 300px;
}
.services-inner {
  max-width: 970px;
}

.card-wrapper {
  position: relative;
}

.content-card {
  max-width: 600px;
  height: 300px;
  float: left;
  background: white;
}

.content-card:last-child {
  max-width: 300px;
  height: 640px;
  position: absolute;
  right: 0;
  top: 0;
}
.content-card:last-child .card-image, .content-card:last-child .card-text {
  width: 100%;
}
.services-stripes-left {
  position: absolute;
  left: 150px;
  bottom: 0;
  z-index: -1;
}

.services-stripes-right {
  position: absolute;
  right: 240px;
  top: -90px;
  z-index: -1;
}

@media screen and (max-width: 992px) {
  .content-card:last-child {
      max-width: 600px;
      height: 300px;
      float: none;
      background: white;
      position: relative;
  }
  .content-card {
    float: none;
    margin: auto;
    margin-bottom: 50px;
    height: inherit;
    margin-left: 15px;
    margin-right: 15px;
  }
  .content-card:nth-child(1n) {
    border-left: 5px solid #1FA6A6;
  }
  .content-card:nth-child(2n) {
    border-left: 5px solid #DFBA05;
  }
  .content-card:nth-child(3n) {
    border-left: 5px solid #DC580D;
  }
  .content-card div {
    padding: 15px;
    width: 100%;
  }
  .content-card h3 {
    margin-bottom: 0;
  }
  .services-template {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .services-template .card-image {
    display: none;
  }
  .services-template.section-background {
    height: auto;
  }
  .services-stripes-left {
    left: 0;
  }
  .services-stripes-right {
    display: none;
  }
}
</style>
