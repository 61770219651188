<template>
  <div class="flex relative items-center justify-center portfolio-template bg-cover bg-center" :style="{ backgroundImage: 'url(' + require('@/assets/background-work.png') + ')' }">
    <div class="portfolio-inner">
      <div class="portfolio-left-width float-left w-2/5 inline-block">
        <div class="portfolio-left m-5" :class="pageName">
          <img class="p-4 rounded-3xl" src="@/assets/singletrack-study-hero-2.png">
        </div>
      </div>
      <div class="portfolio-right-width float-right w-3/5 inline-block">
        <div class="portfolio-right m-5">
          <div class="portfolio-title">
            <div class="mb-8 portfolio-title-header text-left" :class="pageName">
              Singletrack Research Portal
            </div>
            <div class="mb-8 portfolio-title-images text-left" :class="pageName">
              <Carousel :breakpoints="breakpoints">
                <Slide v-for="image in carouselImages" :key="image">
                  <div class="carousel__item"><img :src="image"></div>
                </Slide>
                <template #addons>
                  <Navigation />
                </template>
              </Carousel>
            </div>
          </div>
          <div class="mb-8 portfolio-requirements">
            <div class="portfolio-requirements-header text-left" :class="pageName">
              Product Overview
            </div>
            <div class="portfolio-requirements-content text-left" style="background-position: 50% 65%; background-repeat: no-repeat; background-attachment: fixed" :style="{ backgroundImage: 'url(' + require('@/assets/background-work.png') + ')' }">
              <p class="pb-4 relative">
                Singletrack's Research Portal is a place for Sell-Side oriented companies to distribute their financial research to their Buy-Side/Private clientele.
              </p>
              <p class="relative">
                An out-of-the-box offering is available for clients who'd like to hit the ground running with a production ready solution, whilst a customizable offering exists for clients who'd like their own completely rebranded solution with additional features and functionality.
              </p>
            </div>
          </div>
          <div class="mb-8 portfolio-requirements">
            <div class="portfolio-requirements-header text-left" :class="pageName">
              Responsibilities
            </div>
            <div class="portfolio-requirements-content text-left" style="background-position: 50% 65%; background-repeat: no-repeat; background-attachment: fixed" :style="{ backgroundImage: 'url(' + require('@/assets/background-work.png') + ')' }">
              <p class="pb-4 relative">
                Gather and define the main objectives of our users, mock up a lo-fi user journey, design a hi-fi standard portal offering, develop the front-end of the standard portal offering in collaboration with back-end developers.
              </p>
              <p class="pb-6 relative">
                Role: UI / UX Designer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      pageName: 'singletrack',
      carouselImages: [
        require("@/assets/carouselImages/v1-login.png"),
        require("@/assets/carouselImages/v1-dashboard.png"),
        require("@/assets/carouselImages/v1-research.png"),
        require("@/assets/carouselImages/v1-analyst.png"),
        require("@/assets/carouselImages/v1-company.png"),
        require("@/assets/carouselImages/v1-sector.png"),
        require("@/assets/carouselImages/v1-library.png"),
        require("@/assets/carouselImages/v1-roadshows.png"),
        require("@/assets/carouselImages/v1-roadshow-preview.png"),
      ],
      breakpoints: {
        // 700px and up
        350: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        992: {
          itemsToShow: 2.5,
          snapAlign: 'start',
        },
      },
    }
  }
}
</script>

<style>
.portfolio-template .carousel__item {
  min-height: inherit;
}
.portfolio-template .singletrack .carousel__prev, .portfolio-template .singletrack .carousel__next {
    background-color: #8e5307;
}
.text-shadow {
  text-shadow: 0 3px 6px #0003;
}
.portfolio-template {
  height: 1080px;
  border-radius: 8px;
}
.portfolio-inner {
  width: 1060px;
}
.portfolio-title-images.singletrack {
  margin-bottom: 40px;
}
.portfolio-left.singletrack {
  background: rgb(96,58,17);
  background: linear-gradient(40deg, rgb(96 58 17 / 49%) 0%, rgb(255 136 0 / 57%) 100%);
  border-radius: 8px;
}
.portfolio-title-header.singletrack {
  font-size: 24px;
  padding: 10px 15px;
  background: rgb(96,58,17);
  background: linear-gradient(40deg, rgb(255 136 0 / 57%) 0%, rgb(96 58 17 / 49%) 100%);
  border-radius: 8px;
  color: white;
  margin-bottom: 23px;
}

.portfolio-requirements-header.singletrack {
  font-size: 19px;
  padding: 10px 15px;
  color: white;
  border-radius: 8px 8px 0 0;
  background: rgb(96,58,17);
  background: linear-gradient(40deg, rgb(255 136 0 / 57%) 0%, rgb(96 58 17 / 49%) 100%);
}

.portfolio-requirements-content {
  font-size: 15px;
  padding: 15px;
  color: white;
  position: relative;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
}

.portfolio-requirements-content p {
  z-index: 1;
}

.portfolio-requirements-content:before {
  position: absolute;
  content: '';
  background: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  filter: blur(10px);
  border-radius: 15px;
}

.portfolio-requirements-content:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #001113b5;
}

.portfolio-title-images {
  height: 125px;
  text-align: justify;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;
}

.portfolio-title-images img {
  vertical-align: top;
  display: inline-block;
  border-radius: 8px;
}

.stretch {
  width: 100%;
  display: inline-block;
  font-size: 0;
  line-height: 0
}

@media screen and (max-width: 992px) {
  .portfolio-left-width {
    display: none;
  }
  .portfolio-right-width {
    width: 100%;
  }
  .portfolio-title-images {
    height: inherit;
  }
  .portfolio-title-images.singletrack {
    margin-bottom: 24px;
}
}
</style>
