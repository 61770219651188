<template>
  <div class="shadow-wrapper">
    <div class="text-center">
      <img class="pt-24 pb-16 pl-5 pr-5 inline" :src="logoImg"/>
    </div>
    <img class="-mb-16 relative inline z-20" :src="decorChevron" />
    <div class="triangle z-10"></div>
  </div>

</template>

<script>
import logoImg from "../assets/sg-logo-big.png"
import decorChevron from "../assets/decor-chevron.png"

export default {

  data() {
    return {
      logoImg: logoImg,
      decorChevron: decorChevron
    }
  }
}
</script>

<style>
.shadow-wrapper {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 100%);
}
.text-shadow {
  text-shadow: 0 3px 6px #0003;
}
.oval {
  height: 100px;
  width: 100%;
  background-color: white;
  border-radius: 50%;
}
.triangle {
  border-top: 41px solid #f1f1f1;
  border-left: 48.5vw solid transparent;
  border-right: 48.5vw solid transparent;
  width:0;
  height:0;
  content:"";
  display:block;
  position:absolute;
  overflow:hidden;
  left:0;
  right:0;
  margin:auto;
}
</style>
