<template>
  <div class="flex relative items-center justify-center study-template bg-cover bg-center singletrack2022">
    <div class="study-inner study-row-break">
      <div class="study-tags mb-5">
        <div class="align-top block text-left">
          <div class="ml-5 mr-5">
            <span class="mb-5 study-tag" v-for="tag in tags" :key="tag">
              {{tag}}
            </span>
          </div>
        </div>
      </div>
      <div v-for="(section, index) in caseContent" :key="index" class="study-content m-5 mb-10" :class="(index == 0)? 'study-content-primary' : 'study-content-secondary'">
        <div class="align-top inline-block w-3/5 text-left content-text" :class="(section.image)? 'w-1/2' : 'w-full'">
          <div class="m-5 study-content-inner">
            <p class="pb-4 font-bold study-content-title" v-if="section.title">
              {{section.title}}
            </p>
            <p class="whitespace-pre-line study-content-paragraph">
              {{section.text}}
            </p>
            <p class="pb-4 whitespace-pre-line" v-if="section.textIntro">
              {{section.textIntro}}
            </p>
            <div class="study-content-outro-wrapper">
              <div class="study-content-outro" v-for="image in section.textIntroImages" :key="image">
                <img :src="image.image"> &nbsp;
              </div>
              &nbsp;
              <span class="stretch"></span>
            </div>
            <p class="whitespace-pre-line" v-if="section.textOutro">
              {{section.textOutro}}
            </p>
          </div>
        </div>
        <div class="align-top inline-block w-2/5 content-image" v-if="section.image">
          <div class="m-8 study-image">
            <img class="rounded-lg" :src="section.image" />
          </div>
        </div>
      </div>
      <div class="m-5">
        <Carousel>
          <Slide v-for="image in carouselImages" :key="image">
            <div class="carousel__item"><img :src="image"></div>
          </Slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      visible: false,
      index: 0, // default: 0
      images: [
        require("@/assets/singletrack-v2/singletrack-refresh-thumbnail.png")
      ],
      carouselImages: [
        require("@/assets/singletrack-v2/v2-redesign-dashboard.png"),
        require("@/assets/singletrack-v2/v2-redesign-library.png"),
        require("@/assets/singletrack-v2/v2-redesign-library-filter.png"),
        require("@/assets/singletrack-v2/v2-redesign-research-preview.png"),
        require("@/assets/singletrack-v2/v2-redesign-analyst-preview.png"),
        require("@/assets/singletrack-v2/v2-redesign-sector-profile.png"),
        require("@/assets/singletrack-v2/v2-redesign-company-profile.png"),
        require("@/assets/singletrack-v2/v2-redesign-roadshows.png"),
        require("@/assets/singletrack-v2/v2-redesign-roadshow-preview.png"),
      ],
      tags: ['FinTech', 'SaaS', 'Hi-Fi', 'UI', 'UX', 'Product Design'],
      caseContent: [
        {image: require("@/assets/singletrack-v2/singletrack-refresh-thumbnail.png"), 
                    title: "Singletrack Research Portal 2022 Refresh", 
                    text: "This refresh builds upon the base components previously mentioned in the 'Singletrack Research Portal Case Study'. \n\n As we provide a SaaS product, we have had multiple client requests over the years for additional features and or customised content. \n\n It is during these projects that we've kept our ears open to any pain points all stakeholders have had. \n\n The major pain points that we'd like to address to stay relevant in the competitive FinTech market were as follows: \n\n • The look and feel is a bit dated, too many tables. \n • The desktop experience is a bit stretched and inconsistent. \n • Components don't mesh together well within modals."
        },
        {image: "", title: "Design Decisions", 
                    text: "", 
                    textIntro: "As most users preferred the side navigation to be minimised at all times, certain clients requested we outright remove it from their portal instance. Due to this we have retired the side navigation bar permanently as it was taking up too much page real-estate and providing little in return. Instead, we moved to having a two-tier top navigation bar. \n\n The first tier would be off-site external navigation links, and the second tier would be on-site portal based navigation links. \n\n Our next pain point was that due to using a percentage scaling layout, the portal ended up looking stretched on larger resolutions with chasms of white space between content. \n\n Doing research into the average width of a desktop screen revealed that a vast majority would fit within a 1200px range. Utilising a 1200px max-width allows us to guarantee that no matter how big the users screen, we can provide a consistent user experience.",
                    textOutro: "Various stakeholders mentioned that they enjoyed a blog based layout for reading purposes. Due to this, we decided to adopt a tile based layout. This would allow more visual stimuli during the consumption of research, keeping the user engaged for longer. For example, a tile with an interesting image may facilitate a further click from a user. \n\n We still use tables, however only in cases where showing a large amount of content would benefit from being housed within a very small component. A good example would be a table of companies, a user can quickly scan which companies are covered in the portal.",
                    textIntroImages: [{image: require("@/assets/singletrack-v2/study-comp-new.png")}]}
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  }
}
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  color:  #5341f9;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__pagination-button--active {
  background-color: #5341f9;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  background-color: #5341f9;
}

.study-inner {
  width: 1060px;
}
.study-row-break {
  border-bottom: 1px solid #e5e7eb;
}
.study-image {
  overflow-y: auto;
  max-height: 460px;
}
.study-content img {
  border: 1px solid #e5e7eb;
}

.study-content {
  box-shadow: 0 0 21px #f0f0f0;
  border-radius: 8px;
  height:auto;
  display: flex;
  flex-flow: row column;
}

.content-text {
  border-right: 1px dashed #d7d7d7;
}

.content-text.w-full {
  border: none;
}

.study-tag {
  padding: 14px 17px;
  background: #5341F9;
  color: white;
  font-weight: bold;
  box-shadow: 0 0 21px #E2E2E2; 
  border-radius: 8px;
  margin-right: 10px;
  white-space: nowrap;
  display: inline-block;
}

.study-content-primary .study-content-title {
  font-size: 26px;
}

.study-content-primary .study-content-paragraph {
  font-size: 16px;
}

.study-content-secondary .study-content-title {
  font-size: 20px;
}

.study-content-secondary .content-text {
  height: auto;
  display: flex;
}

.study-content-secondary .study-content-paragraph {
  font-size: 15px;
}

.study-content-secondary .study-content-inner {
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-left: 2px dashed #5341f9;
  padding: 0 40px;
}

.study-content-outro-wrapper {
  text-align: justify;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;
}

.study-content-outro {
  vertical-align: top;
  display: inline;
}

.study-content-outro img {
  border: none;
  display: inline-block;
}

.stretch {
  width: 100%;
  display: inline-block;
  font-size: 0;
  line-height: 0
}

@media screen and (max-width: 992px) {
  .study-content .content-image, .study-content .content-text, .study-content .content-image-after {
    width: 100%;
  }
  .study-content {
    flex-direction: column;
  }
  .study-image {
    max-height: 528px;
  }
  .study-content-outro {
    display: inline-block;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .singletrack2022 .content-image {
    display: none;
  }
}

</style>
