<template>
  <div class="hello text-center">
      <div>
        <router-link to="/"><span class="navbar-logo px-10"><img class="inline" src="../assets/sg-logo.png"/></span></router-link>
        <span class="navbar-option px-10 text-shadow text-lg" :class="{'font-bold text-lg':nav.message == selected}" @click="selected = nav.message" v-for="nav in navItems" :key="nav.message">
          <template v-if="nav.message == 'Home'">
            <router-link active-class="active" to="/">{{nav.message}}</router-link>
          </template>
          <template v-else-if="nav.message == 'Portfolio'">
            <router-link active-class="active" to="/portfolio/Singletrack">{{nav.message}}</router-link>
          </template>
          <template v-else>
            {{nav.message}}
          </template>
        </span>
        <span class="px-5 py-4 rounded-lg bg-lime text-white text-shadow shadow-md text-lg navbar-in-touch">WIP</span>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navItems: [
          { message: 'Home' },
          { message: 'Portfolio'},
          { message: 'Services'}
        ],
    }
  }
}
</script>

<style>
.text-shadow {
  text-shadow: 0 3px 6px #0003;
}

.router-link-exact-active {
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .navbar-in-touch {
    width: 150px;
    display: block;
    margin: auto;
    margin-top: 25px;
  }
  .navbar-option {
    padding: 0 30px;
  }
  .navbar-logo {
    display: block;
    padding-bottom: 20px;
  }
}

</style>
