import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Singletrack2022 from "@/views/Singletrack2022.vue";
import Singletrack from "@/views/Singletrack.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/portfolio/Singletrack2022",
    name: "Singletrack2022",
    component: Singletrack2022,
  },
  {
    path: "/portfolio/Singletrack",
    name: "Singletrack",
    component: Singletrack,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;