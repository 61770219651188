<template>
  <div class="flex relative items-center justify-center showcase-template section-background shadow-wrapper relative">
    <div class="left-geometry absolute left-0">
      <img :src="require('@/assets/left-geometry.png')">
    </div>
    <div class="showcase-inner relative">
      <img class="showcase-image absolute" :src="require('@/assets/shane-gunnell.png')">
      <div class="showcase-text relative right-0 text-left float-right">
        <h2 class="text-5xl">{{companyNameEmphasis}} <span>{{companyNameItalic}}</span></h2>
        <h4 class="text-lg font-bold pt-2">{{companyTagline}}</h4>
        <p class="whitespace-pre-line pt-2">{{companyAbout}}</p>
      </div>
      <div class="showcase-list clear-both float-right">
        <div class="pt-6 pb-6 clear-both">
          <span class="shadow-md float-left p-4 bg-white rounded-full flex items-center justify-center inline">
            <svg class="text-lime w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
          </span>
          <span class="float-left align-right p-5 inline-block">
            Based in Central London
          </span>
        </div>
        <div class="pt-6 pb-6 clear-both">
          <span class="shadow-md float-left p-4 bg-white rounded-full flex items-center justify-center inline ">
              <svg class="text-lime w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
          </span>
          <span class="float-left align-right p-5 inline-block">
            Global Outreach for all Services
          </span>
        </div>
        <div class="pt-6 pb-6 clear-both">
          <span class="shadow-md float-left p-4 bg-white rounded-full flex items-center justify-center inline">
            <svg class="text-lime w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"></path></svg>
          </span>
          <span class="float-left align-right p-5 inline-block">
            UI/UX Consultations Available
          </span>
        </div>
      </div>

    </div>
    <div class="right-geometry absolute right-0">
      <img :src="require('@/assets/right-geometry.png')">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyNameEmphasis: "Shane",
      companyNameItalic:"Gunnell",
      companyTagline: "Our Purpose",
      companyAbout: "Enabling smoother user interactions through a sympathetic approach to design. \n\n By investing time into your users, your users invest more time into you; this symbiotic feedback loop is one of the best ways to stay relevant in the ever changing competitive world of tomorrow. \n\n Years in the SaaS industry have taught me that championing the users needs and wants throughout the entire project pipeline brings a product ever closer to satisfying the desires of the audience. It'll never be perfect, nor will it ever truly be finished, but it'll be damn close.",
      centredImages: [
        {image: require("@/assets/singletrack-promo-left.png")},
        {image: require("@/assets/singletrack-promo-middle.png")},
        {image: require("@/assets/singletrack-promo-right.png")}
      ]
    }
  }
}
</script>

<style>
.text-shadow {
  text-shadow: 0 3px 6px #0003;
}
.showcase-template.section-background {
  height: 1008px;
}
.showcase-inner {
  width: 970px;
  height: 640px;
}
.showcase-list {
  width: 420px;
}
.showcase-text {
  width: 660px;
}
.showcase-image {
  top: 12px;
  left: -17px;
}

@media screen and (max-width: 992px) {
  .showcase-text {
    float: none;
    margin: auto;
    max-width: 660px;
  }
  .showcase-image {
    display: none;
  }
  .showcase-inner {
    width: auto;
    max-width: 970px;
  }
  .showcase-text {
    width: auto;
    max-width: 660px;
  }
  .showcase-list {
    float: left;
    width: auto;
    max-width: 370px
  }
  .left-geometry {
    left: -200px;
  }
  .showcase-inner {
    padding: 15px;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .showcase-template.section-background {
    height: auto;
  }

}
</style>
