<template>
  <div class="flex relative items-center justify-center study-template bg-cover bg-center">
    <div class="study-inner study-row-break">
      <div class="study-tags mb-5">
        <div class="align-top block text-left">
          <div class="ml-5 mr-5">
            <span class="mb-5 study-tag" v-for="tag in tags" :key="tag">
              {{tag}}
            </span>
          </div>
        </div>
      </div>
      <div v-for="(section, index) in caseContent" :key="index" class="study-content m-5 mb-10" :class="(index == 0)? 'study-content-primary' : 'study-content-secondary'">
        <div class="align-top inline-block w-3/5 text-left content-text" :class="(section.image)? 'w-1/2' : 'w-full'">
          <div class="m-5 study-content-inner">
            <p class="pb-4 font-bold study-content-title" v-if="section.title">
              {{section.title}}
            </p>
            <p class="whitespace-pre-line study-content-paragraph">
              {{section.text}}
            </p>
            <p class="pb-4 whitespace-pre-line" v-if="section.textIntro">
              {{section.textIntro}}
            </p>
            <div class="study-content-outro-wrapper">
              <div class="study-content-outro" v-for="image in section.textIntroImages" :key="image">
                <img :src="image.image"> &nbsp;
              </div>
              &nbsp;
              <span class="stretch"></span>
            </div>
            <p class="whitespace-pre-line" v-if="section.textOutro">
              {{section.textOutro}}
            </p>
          </div>
        </div>
        <div class="align-top inline-block w-2/5 content-image" v-if="section.image">
          <div class="m-8 study-image">
            <div class="pic" @click="() => showImg(index)">
              <img :src="section.image" />
            </div>
          </div>
        </div>
      </div>
      <div class="m-5">
        <Carousel>
          <Slide v-for="image in carouselImages" :key="image">
            <div class="carousel__item"><img :src="image"></div>
          </Slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
  <vue-easy-lightbox
    :visible="visible"
    :imgs="images"
    :index="index"
    @hide="handleHide"
  ></vue-easy-lightbox>
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  components: {
    VueEasyLightbox,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      visible: false,
      index: 0, // default: 0
      images: [
        require("@/assets/singletrack-study.png"),
        require("@/assets/singletrack-study-dashboard.png"),
        require("@/assets/singletrack-study-library.png"),
        require("@/assets/singletrack-study-roadshow.png"),
        require("@/assets/singletrack-study-research.png"),
        require("@/assets/singletrack-study-analyst.png"),
        require("@/assets/singletrack-study-company.png"),
        require("@/assets/singletrack-study-sector.png"),
      ],
      carouselImages: [
        require("@/assets/carouselImages/v1-login.png"),
        require("@/assets/carouselImages/v1-dashboard.png"),
        require("@/assets/carouselImages/v1-research.png"),
        require("@/assets/carouselImages/v1-analyst.png"),
        require("@/assets/carouselImages/v1-company.png"),
        require("@/assets/carouselImages/v1-sector.png"),
        require("@/assets/carouselImages/v1-library.png"),
        require("@/assets/carouselImages/v1-roadshows.png"),
        require("@/assets/carouselImages/v1-roadshow-preview.png"),
        require("@/assets/carouselImages/v1-roadshow-preview-booking.png"),
        require("@/assets/carouselImages/v1-login-mobile.png"),
        require("@/assets/carouselImages/v1-dashboard-mobile.png"),
        require("@/assets/carouselImages/v1-dashboard-mobile-sidebar.png"),
        require("@/assets/carouselImages/v1-dropdown-mobile.png"),
        require("@/assets/carouselImages/v1-research-mobile.png"),
        require("@/assets/carouselImages/v1-analyst-mobile.png"),
        require("@/assets/carouselImages/v1-library-mobile.png"),
        require("@/assets/carouselImages/v1-library-filters-mobile.png"),
      ],
      tags: ['FinTech', 'SaaS', 'Lo-Fi', 'Hi-Fi', 'UI', 'UX', 'Product Design', 'Development'],
      caseContent: [
        {image: require("@/assets/singletrack-study.png"), 
                    title: "Singletrack Research Portal - Case Study", 
                    text: "When the research portal was initially created, all we had was a set of data housed within an unfinished table based UI. We needed to find a way to share as much data with the user as quickly as possible, as there was quite a lot of content to consume and we did not want to hamper the users browsing experience. \n\n As this is a SaaS, the research portal has grown significantly over time; each client customized project adding more and more features back into the standard portal. \n\n The main objectives for a user on our portal are as follows: \n\n • Access research via a personalised email link. \n • Access research via a direct login to the portal. \n • Search the portal for specific research based on their subscription. \n • Preview a specific piece of research. \n • Download the research PDF/XLS. \n • Read more about the author of the research. \n • Find more info about the company mentioned in the research. \n •  Find more info about the sector the research covers. \n • View all roadshows available to attend. \n • View a specific roadshow. \n\n Based on our users main objectives, we split the portal into 3 specific pages: Dashboard, Library, Roadshows."
        },
        {image: require("@/assets/singletrack-study-dashboard.png"), 
                    title: "Dashboard",
                    text: "The following components live here, Your Research, Popular Research, Companies, Roadshows. A client can select which set of components they'd like to appear here. \n\n Your Research \n\n A table with research tailored specifically to the user based on their subscription; a bulk set of tags. Users can then select/deselect tags that they'd like to view research on, and their choices will drive what research appears in this component. \n\n Popular Research \n\n A table of research with the most downloads in the past X days. This is sometimes used to upsell other research packages, providing access to a document that may not necessarily be available. \n\n Companies \n\n A table of all the companies available in the system, allowing the user to view the company preview and see any research specific to that company. \n\n Roadshows \n\n A table of all available roadshows. A user can then preview a specific roadshow and request/cancel attendance on a specific roadshow." 
        },
        {image: require("@/assets/singletrack-study-library.png"), 
                    title: "Library Page", 
                    text: "A table that shows all the research in a users subscription, regardless of their custom subscription tags. \n\n A filter panel with advanced filter capabilities, allowing for specific tag/text searches."
        },
        {image: require("@/assets/singletrack-study-roadshow.png"), 
                    title: "Roadshows/Events Page", 
                    text: "A table of all available roadshows. A user can then preview a specific roadshow and request/cancel attendance on a specific roadshow. \n\n A calendar view of all available roadshows." 
        },
        {image: require("@/assets/singletrack-study-research.png"), 
                    title: "Research Preview", 
                    text: "When clicking on a research document in a table, a modal will open with additional information about that research document. \n\n We sectioned content into the following fields: Publication Type, Rating, Publish Date, Title, Summary, Detail, Download Document, Author(s), Company Details, Related Research. All primary content will be on the left, any secondary content would be on the right. \n\n How we sorted information into primary/secondary broke down to a simple question: 'Why am I here?'. I'm here to preview some research, therefore anything specific to the research in question is primary. Anything else would be secondary. \n\n Once finished consuming said content, the user can close the modal to return back to the screen they were on previously, so as to not disrupt the users journey." 
        },
        {image: require("@/assets/singletrack-study-analyst.png"), 
                    title: "Analyst Preview", 
                    text: "Clicking on an analyst name in a table, or within a Research Preview, the Analyst Preview modal will appear. \n\n Primary information: Author's name, contact information, about. \n\n Secondary information: A table of companies that the analyst covers, and a table showing all the research documents written by the analyst." 
        },
        {image: require("@/assets/singletrack-study-company.png"), 
                    title: "Company Preview", 
                    text: "Clicking on a company name in a table, or within a Research Preview, the Company Preview modal will appear. \n\n Primary information: Covering analyst, country, contact information, sector, about. \n\n Secondary information: Financial data imported from a third party data source, a table showing upcoming company roadshows, and a table showing company specific research documents." 
        },
        {image: require("@/assets/singletrack-study-sector.png"), 
                    title: "Sector Preview", 
                    text: "Clicking on a sector in a table, or within a Research Preview, the Sector Preview modal will appear. \n\n Primary information: Covering analyst, link to the financial authority overseeing the industry, link to the specific model for the company. \n\n Secondary information: Financial data imported from a third party data source, a table showing companies in the sector, and a table showing sector specific research documents." 
        },
        {image: require("@/assets/singletrack-study-subscriptions.png"), 
                    title: "Subscriptions", 
                    text: "A user may specify what they are interested in out of their available research subscription plans. \n\n This drives what documents appear in the 'Your Research' component on the dashboard, as well as what research related emails they will receive. \n\n Subscriptions can be accessed via the user name dropdown." 
        },
        {image: require("@/assets/singletrack-study-login.png"), 
                    title: "Login", 
                    text: "A basic form requiring the user name and password of a user to log in, with 'Remember me' and 'Reset password' capabilities. \n\n When 'Remember me' is enabled, this allows a cookie to be saved for an extended period of time, thus the user does not need to log in again until after the cookie expires." 
        },
        {image: require("@/assets/singletrack-study-reset.png"), 
                    title: "Reset Password", 
                    text: "A basic form requiring the user name. \n\n Pressing submit sends off an email with a personalised link to the user to change their password." 
        },
        {image: require("@/assets/singletrack-study-change.png"), 
                    title: "Change Password", 
                    text: "Following an email from the 'Reset Password' page would take the user here. \n\n The user enters a new password twice to avoid any typos, and can submit to log in again with their new password." 
        },
        {image: "", title: "Design Decisions", 
                    text: "", 
                    textIntro: "As we'd already gathered the main use points of our users & clients, we now had the task of displaying all this content in an easy to consume manner. Due to this, we opted for a symmetrical and consistent approach. Each component shared the same styling, and could easily be added to the page using a grid system:",
                    textOutro: "We used a navigation sidebar to provide a management portal feel. This sidebar could be minimised/maximised depending on user choice. This would allow the user to keep the sidebar minimised for extra page real estate, and allow them to maximise it when navigation was required. Internal portal navigation links are housed here, whereas the top navigation would house links heading off-site, such as a clients main website.\n\n Any user specific settings would live in the top right of the navbar, where the user name would reside. Clicking this would expand a dropdown for resetting the users password and logging out. \n\n The logo resides top left, conforming to standard web practices. It's also the first place a user will look, thus allowing for greater retention of the brand in the mind. \n\n Any non-primary content called via further user interaction on the page would be housed within a modal; such as previewing research, authors, companies, sectors, roadshows.",
                    textIntroImages: [{image: require("@/assets/study-comp-1.png")}, {image: require("@/assets/study-comp-2.png")}, {image: require("@/assets/study-comp-3.png")}, {image: require("@/assets/study-comp-4.png")}]}
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  }
}
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  color:  #5341f9;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__pagination-button--active {
  background-color: #5341f9;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  background-color: #5341f9;
}

.study-inner {
  width: 1060px;
}
.study-row-break {
  border-bottom: 1px solid #e5e7eb;
}
.study-image {
  overflow-y: auto;
  max-height: 460px;
}
.study-content img {
  border: 1px solid #e5e7eb;
}

.study-content {
  box-shadow: 0 0 21px #f0f0f0;
  border-radius: 8px;
  height:auto;
  display: flex;
  flex-flow: row;
}

.content-text {
  border-right: 1px dashed #d7d7d7;
}

.content-text.w-full {
  border: none;
}

.study-tag {
  padding: 14px 17px;
  background: #5341F9;
  color: white;
  font-weight: bold;
  box-shadow: 0 0 21px #E2E2E2; 
  border-radius: 8px;
  margin-right: 10px;
  white-space: nowrap;
  display: inline-block;
}

.study-content-primary .study-content-title {
  font-size: 26px;
}

.study-content-primary .study-content-paragraph {
  font-size: 16px;
}

.study-content-secondary .study-content-title {
  font-size: 20px;
}

.study-content-secondary .content-text {
  height: auto;
  display: flex;
}

.study-content-secondary .study-content-paragraph {
  font-size: 15px;
}

.study-content-secondary .study-content-inner {
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-left: 2px dashed #5341f9;
  padding: 0 40px;
}

.study-content-outro-wrapper {
  text-align: justify;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;
}

.study-content-outro {
  vertical-align: top;
  display: inline;
}

.study-content-outro img {
  border: none;
  display: inline-block;
}

.stretch {
  width: 100%;
  display: inline-block;
  font-size: 0;
  line-height: 0
}

@media screen and (max-width: 992px) {
  .study-content .content-image, .study-content .content-text, .study-content .content-image-after {
    width: 100%;
  }
  .study-content {
    flex-direction: column;
  }
  .study-image {
    max-height: 528px;
  }
  .study-content-outro {
    display: inline-block;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}

</style>
