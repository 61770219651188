<template>
  <div class="flex relative items-center justify-center section-background bg-cover bg-center" :style="{ backgroundImage: 'url(' + require('@/assets/background-work.png') + ')' }">
    <img class="p-8 inline" v-for="image in centredImages" :key="image.image" :src="image.image">
    <div class="absolute bottom-16 tracking-wide rounded-lg bg-lime text-white text-shadow shadow-md text-xl">
      <router-link class="px-5 py-4 block" active-class="active" to="/portfolio/Singletrack">Portfolio <svg class="align-text-top inline w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg></router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      centredImages: [
        {image: require("@/assets/singletrack-promo-left.png")},
        {image: require("@/assets/singletrack-promo-middle.png")},
        {image: require("@/assets/singletrack-promo-right.png")}
      ]
    }
  }
}
</script>

<style>
.text-shadow {
  text-shadow: 0 3px 6px #0003;
}
.section-background {
  height: 1080px;
  overflow: hidden;
}
</style>
