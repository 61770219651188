import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import router from './router'
import VueEasyLightbox from 'vue-easy-lightbox'

const app = createApp(App);
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDZGHWnczMPdZ631EUl1z6pCuBwXsa2whU',
    },
})
app.use(VueEasyLightbox)
app.use(router)
app.mount('#app')
