<template>
  <div class="portfolio-nav shadow-md">
    <NavBar></NavBar>
  </div>
  <div id="nav" class="mt-5 mb-5">
      <router-link to="/portfolio/Singletrack">Singletrack Research</router-link> |
      <router-link to="/portfolio/Singletrack2022">Singletrack Research 2022</router-link>
  </div>
  <div class="portfolio-wrapper m-5 relative shadow-lg">
    <Singletrack></Singletrack>
  </div>
  <SingletrackStudy></SingletrackStudy>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Singletrack from '@/components/Singletrack.vue'
import SingletrackStudy from '@/components/SingletrackStudy.vue'


export default {
  name: 'App',
  components: {
    NavBar,
    Singletrack,
    SingletrackStudy
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
.portfolio-nav {
  padding-bottom: 25px;
}
.portfolio-wrapper {
  margin-top: 0;
}
</style>
