<template>
  <div class="flex relative items-center justify-center approach-template section-background shadow-wrapper relative">
    <div class="approach-inner">
      <h2 class="text-5xl">{{headerEmphasis}} <span>{{headerItalic}}</span></h2>
      <img class="methodical-image absolute" :src="require('@/assets/methodical-image.png')">
      <div class="approach-card-holder float-right pt-14">
        <div v-for="item in approach" :key="item" class="approach-card p-6 background-white shadow-md text-left mb-10">
          <span class="text-lime font-bold">{{item.header}}: </span>
          <span class="whitespace-pre-line">{{item.text}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerEmphasis: "Methodical",
      headerItalic:"Approach",
      approach: [
        {header: "User Experience", text: "Truly getting to know a user may seem like a hard task, but it all begins with a simple conversation. It's amazing what we can find out by talking to people."},
        {header: "User Interface", text: "Great interfaces don't build themselves, nor are they ever built in isolation. By combining user feedback and industry research, we aim to visually please and fundamentally appease."}
      ]
    }
  }
}
</script>

<style>
.text-shadow {
  text-shadow: 0 3px 6px #0003;
}
.approach-template {
  width: 100%;
  background-color: #2B4141;
  overflow: hidden;
}
.approach-template.section-background {
  height: 1008px;
}
.approach-inner {
  width: 970px;
  height: 640px;
}

.showcase-list {
  width: 370px;
}
.showcase-text {
  max-width: 660px;
}
.approach-card {
  max-width: 430px;
  background: white;
}
.methodical-image {
  width: 550px;
}

@media screen and (max-width: 992px) {
  .methodical-image {
    display: none;
  }
  .approach-card {
    margin: auto;
    margin-bottom: 20px;
  }
  .approach-card-holder {
    float: none;
  }
  .approach-inner {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .approach-template.section-background {
    height: auto;
  }
}
</style>
