<template>
  <NavBar></NavBar>
  <Hero></Hero>
  <SectionBackground></SectionBackground>
  <ShowCase></ShowCase>
  <Approach></Approach>
  <Services></Services>
  <Responsive></Responsive>
  <Skillset></Skillset>
  <Map></Map>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Hero from '@/components/Hero.vue'
import SectionBackground from '@/components/SectionBackground.vue'
import ShowCase from '@/components/ShowCase.vue'
import Approach from '@/components/Approach.vue'
import Services from '@/components/Services.vue'
import Responsive from '@/components/Responsive.vue'
import Skillset from '@/components/Skillset.vue'
import Map from '@/components/Map.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Hero,
    SectionBackground,
    ShowCase,
    Approach,
    Services,
    Responsive,
    Skillset,
    Map
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
</style>
