<template>
  <div class="flex h-screen relative items-center justify-center responsive-template section-background relative">
    <img class="responsive-water absolute" :src="decorImages[2].image">
    <div class="responsive-inner relative max-w-xlg">
      <h2 class="text-5xl text-right mb-12 responsive-header">{{headerEmphasis}} <span>{{headerItalic}}</span></h2>
      <img class="responsive-wood float-right absolute top-16" :src="decorImages[0].image">
      <img class="responsive-phone absolute -top-32 left-16" :src="decorImages[1].image">
      <div class="responsive-text-holder rounded-md shadow-md p-5 pb-0 w-1/2 float-right relative right-16 text-left">
        <p v-for="text in responsiveText" :key="text" class="pb-4 whitespace-pre-line">
          {{text.paragraph}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerEmphasis: "Responsive",
      headerItalic:"& Fluid",
      decorImages: [
        {image: require("@/assets/responsive-wood.png")},
        {image: require("@/assets/responsive-phone.png")},
        {image: require("@/assets/responsive-water.png")}
      ],
      responsiveText: [
        {paragraph: "'With great power comes great responsiveness', or something like that. \n \n No matter how powerful a product, if it can't render well on multiple devices, it can't reach a large portion of users. \n \n We always consider whether a proposed solution would work on smaller devices, or how the content may be summarised for the users convenience."}
      ]
    }
  }
}
</script>

<style>
.responsive-header {
  padding-right: 100px;
}
.responsive-text-holder {
  background-color: #FFFAE9;
  color: #474747;
}
.responsive-text-holder span {
  color: #0063AB;
}
.responsive-inner {
  max-width: 970px;
}
@media screen and (max-width: 992px) {
  .responsive-text-holder {
    width: calc(100% - 30px);
    right: auto;
    float: none;
    margin: auto;
  }
  .responsive-wood, .responsive-water, .responsive-phone {
    display: none;
  }
  .responsive-header {
    text-align: center;
    padding: 15px;
  }
  .responsive-template {
    height: auto;
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
</style>
